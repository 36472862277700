<script>
/* eslint-disable no-unused-vars */
export default {
  name: "gt-tabs",
  props: {
    activeTabColor: String,
    activeTextColor: String,
    disabledColor: String,
    disabledTextColor: String,
    /**
     * Tab title position: center | bottom | top
     */
    textPosition: {
      type: String,
      default: "center"
    },
    /**
     * Tab type: tabs | pills
     */
    type: {
      type: String,
      default: "tabs"
    },
    direction: {
      type: String,
      default: "horizontal"
    },
    /**
     * Centers the tabs and makes the container div full width
     */
    centered: Boolean,
    value: [String, Number, Object],
    tabIndex:{
      type:Number,
      default:0,
      required:false
    }
  },
  data() {
    return {
      activeTabIndex: this.tabIndex,
      tabs: []
    };
  },
  computed: {
    isTabShape() {
      return this.type === "tabs";
    },
    isStacked() {
      return this.direction === "vertical";
    },
    classList() {
      let navType = this.isTabShape
        ? "nav-tabs md-tabs tab-timeline"
        : "nav-pills";
      let centerClass = this.centered ? "nav-justified" : "";
      let isStacked = this.isStacked ? "nav-stacked" : "";
      return `nav ${navType} ${centerClass} ${isStacked}`;
    },
    stackedClass() {
      return this.isStacked ? "stacked" : "";
    },
    activeTabStyle() {
      return {
        backgroundColor: this.activeTabColor,
        color: this.activeTextColor
      };
    }
  },
  methods: {
    navigateToTab(index, route) {
      this.changeTab(this.activeTabIndex, index, route);
    },
    activateTab(index) {
      this.activeTabIndex = index;
      let tab = this.tabs[index];
      tab.active = true;
      this.$emit("input", tab.title);
    },
    changeTab(oldIndex, newIndex, route) {
      let oldTab = this.tabs[oldIndex] || {};
      let newTab = this.tabs[newIndex];
      if (newTab.disabled) return;
      this.activeTabIndex = newIndex;
      oldTab.active = false;
      newTab.active = true;
      this.$emit("input", this.tabs[newIndex].title);
      this.$emit("tab-change", newIndex, newTab, oldTab);
      this.tryChangeRoute(route);
    },
    tryChangeRoute(route) {
      if (this.$router && route) {
        this.$router.push(route);
      }
    },
    addTab(item) {
      const index = this.$slots.default.indexOf(item.$vnode);
      this.tabs.splice(index, 0, item);
    },
    removeTab(item) {
      const tabs = this.tabs;
      const index = tabs.indexOf(item);
      if (index > -1) {
        tabs.splice(index, 1);
      }
    },
    getTabs() {
      if (this.$slots.default) {
        return this.$slots.default.filter(comp => comp.componentOptions);
      }
      return [];
    },
    findTabAndActivate(tabNameOrIndex) {
      let indexToActivate = this.tabs.findIndex(
        (tab, index) => tab.title === tabNameOrIndex || index === tabNameOrIndex
      );
      // if somehow activeTabIndex is not reflected in the actual vue-tab instance, set it.
      if (
        indexToActivate === this.activeTabIndex &&
        !this.tabs[this.activeTabIndex].active
      ) {
        this.tabs[this.activeTabIndex].active = true;
      }
      if (indexToActivate !== -1) {
        this.changeTab(this.activeTabIndex, indexToActivate);
      } else {
        this.changeTab(this.activeTabIndex, 0);
      }
    },
    renderTabTitle(index, position = "top") {
      if (this.tabs.length === 0) return;
      let tab = this.tabs[index];
      let { active, title } = tab;
      let titleStyles = { color: this.activeTabColor };
      if (position === "center") titleStyles.color = this.activeTextColor;
      let simpleTitle = (
        <div>
          <span
            class={`title title_${position}`}
            style={active ? titleStyles : {}}
          >
            {position === "center" && this.renderIcon(index)}
            {title}
          </span>
        </div>
      );

      if (tab.$slots.title) return tab.$slots.title;
      if (tab.$scopedSlots.title)
        return tab.$scopedSlots.title({
          active: active,
          title: title,
          position: position,
          icon: tab.icon,
          data: tab.tabData
        });
      return simpleTitle;
    },
    renderIcon(index) {
      if (this.tabs.length === 0) return;
      let tab = this.tabs[index];
      let { icon } = tab;
      let simpleIcon = <i class={icon}>&nbsp;</i>;
      if (!tab.$slots.title && icon) return simpleIcon;
    },
    tabStyles(tab) {
      if (tab.disabled) {
        return {
          backgroundColor: this.disabledColor,
          color: this.disabledTextColor
        };
      }
      return {};
    },
    renderTabs() {
      return this.tabs.map((tab, index) => {
        if (!tab) return;
        let { route, id, title, icon, tabId } = tab;
        let active = this.activeTabIndex === index;
        return (
          <li
            name="tab"
            onClick={() => !tab.disabled && this.navigateToTab(index, route)}
            class={["nav-item", { disabled: tab.disabled }]}
            key={title}
            id={`t-${tabId}`}
            aria-selected={active}
            aria-controls={`p-${tabId}`}
            role="tab"
          >
            {this.textPosition === "top" &&
              this.renderTabTitle(index, this.textPosition)}
            <a
              href="#"
              class={["nav-link", { active: active }]}
              onClick={e => {
                e.preventDefault();
                return false;
              }}
              style={active ? this.activeTabStyle : this.tabStyles(tab)}
              role="tab"
            >
              {this.textPosition !== "center" &&
                !tab.$slots.title &&
                this.renderIcon(index)}
              {this.textPosition === "center" &&
                this.renderTabTitle(index, this.textPosition)}
            </a>
            {this.textPosition === "bottom" &&
              this.renderTabTitle(index, this.textPosition)}
          </li>
        );
      });
    }
  },
  render() {
    const tabList = this.renderTabs();
    return (
      <div class={["vue-tabs", this.stackedClass]}>
        <div
          class={[
            { "nav-tabs-navigation": !this.isStacked },
            { "left-vertical-tabs": this.isStacked }
          ]}
        >
          <div class={["tab-header card", this.stackedClass]}>
            <ul class={this.classList} role="tablist">
              {tabList}
            </ul>
          </div>
        </div>
        <div class={["tab-content", { "right-text-tabs": this.isStacked }]}>
          {this.$slots.default}
        </div>
      </div>
    );
  },
  watch: {
    tabs(newList) {
      if (newList.length > 0 && !this.value) {
        if (newList.length <= this.activeTabIndex) {
          this.activateTab(this.activeTabIndex - 1);
        } else {
          this.activateTab(this.activeTabIndex);
        }
      }
      if (newList.length > 0 && this.value) {
        this.findTabAndActivate(this.value);
      }
    },
    value(newVal) {
      this.findTabAndActivate(newVal);
    }
  }
};
</script>
<style scoped>
</style>
