var render, staticRenderFns
import script from "./gtTabs.vue?vue&type=script&lang=js&"
export * from "./gtTabs.vue?vue&type=script&lang=js&"
import style0 from "./gtTabs.vue?vue&type=style&index=0&id=726a242b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "726a242b",
  null
  
)

component.options.__file = "gtTabs.vue"
export default component.exports